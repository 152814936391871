type Handler = (payload: AnyType) => void
const hooks: Record<string, Handler[] | undefined> = {}

export function useEvents() {
  return {
    emit: (event: string, payload?: AnyType) => {
      hooks[event]?.forEach((handler) => handler(payload))
    },
    on: (event: string, handler: Handler) => {
      if (!hooks[event]) {
        hooks[event] = []
      }

      if (!hooks[event]?.includes(handler)) {
        hooks[event].push(handler)
      }
    },
    off: (handler: Handler) => {
      Object.values(hooks).forEach((val) => {
        if (val && val.includes(handler)) {
          val.splice(val.indexOf(handler), 1)
        }
      })
    },
  }
}
