import { toast } from 'vue3-toastify'
import {
  LazySettingsIntegrationsModal,
  LazySettingsIntegrationsShopifyInstallModal,
  LazyShipmentSync,
  LazyShipmentUpload,
} from '#components'

import { useEvents } from '~/composables/events'

export const ecomTypes = [
  { type: 'shoptet', available: true },
  { type: 'eshop-rychle', available: true },
  { type: 'woocommerce', available: true },
  { type: 'shopify', available: true },
  { type: 'prestashop', available: true },
  { type: 'upgates', available: true },
]

export const integrationReload = ref<boolean>(false)

export const newIntegration = (type: string) => {
  if (type === 'shopify') {
    openShopifyInstallModal()
  } else {
    openIntegrationModal(undefined, type)
  }
}

const openShopifyInstallModal = () => {
  const modal = openModal(
    LazySettingsIntegrationsShopifyInstallModal,
    {
      onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
      },
    },
    {
      size: 'lg',
    },
  )
}

export const openIntegrationModal = (id?: number, type?: string) => {
  const { $i18n } = useNuxtApp()
  const router = useRouter()
  let doneAlertType: string | null = null

  const modal = openModal(
    LazySettingsIntegrationsModal,
    {
      id,
      ecomType: type,
      onClose() {
        modal.close()
      },
      onDone(type: string) {
        modal.close()
        router.push('/settings/integrations')
        doneAlertType = type
        integrationReload.value = true
      },
    },
    {
      size: 'lg',
      onClosed() {
        if (doneAlertType === 'create') {
          toast.success($i18n.t('pages.settings.integrations.modal.created.title'))
        }
      },
    },
  )
}

export const openSyncIntegrationsModal = () => {
  const modal = openModal(
    LazyShipmentSync,
    {
      onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
      },
    },
  )
}

export const openUploadModal = () => {
  const modal = openModal(
    LazyShipmentUpload,
    {
      onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
        useEvents().emit('shipment-file-upload')
      },
    },
    {
      size: 'lg',
    },
  )
}
